import React from "react";
import { clientsData } from "../../clientsData";

const Clients = () => {
  return (
    <div className="clients-container">
      <div className="section-title">Clients</div>
      <div className="clients-box">
        {clientsData.map((client, idx) => (
          <div className="client-container" key={idx}>
            <div className="client-box">
              <a href={client.link} rel="noopener noreferrer" target="_blank">
                <div className="image-box">
                  <img src={client.logo} alt="" />
                </div>
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Clients;
