import React from "react";
import { Route, Switch } from "react-router-dom";
import Navbar from "./navbar/Navbar";
import Splash from "./splash/Splash";
import Details from "./details/Details";
import About from "./about/About";
import Footer from "./footer/Footer";
import Widget from "./widget/Widget";

const App = () => {
  return (
    <div className="App">
      <Navbar />
      <Widget />
      <Switch>
        <Route exact path="/" component={Splash} />
        <Route exact path="/about" component={About} />
        <Route exact path="/:category" component={Splash} />
        <Route exact path="/:category/:details" component={Details} />
      </Switch>
      <Footer />
    </div>
  );
};

export default App;
