import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Projects from "../projects/Projects";
import { masterData as projects } from "../../masterData";

const Splash = () => {
  const { category } = useParams();
  const [filtProjects, setFiltProjects] = useState([]);

  useEffect(() => {
    setFiltProjects(
      projects.filter(
        (ele) => ele.category === category || category === undefined
      )
    );
  }, [category]);

  return (
    <section className="splash-container">
      {!filtProjects.length ? (
        <Projects projects={projects} />
      ) : (
        <Projects projects={filtProjects} />
      )}
    </section>
  );
};

export default Splash;
