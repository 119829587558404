import React from "react";
import Project from "./Project";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { motion } from "framer-motion";

const Projects = ({ projects }) => {
  if (!projects) return null;

  return (
    <div className="projects-container">
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
        <Masonry>
          {projects.map((proj, idx) => (
            <motion.div key={idx}>
              <Project project={proj} />
            </motion.div>
          ))}
        </Masonry>
      </ResponsiveMasonry>
    </div>
  );
};

export default Projects;
