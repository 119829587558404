import React, { useEffect, useState } from "react";
import { SiMinutemailer } from "react-icons/si";
import { motion } from "framer-motion";

const Form2 = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [isFilled, setIsFilled] = useState(false);

  useEffect(() => {
    if (name !== "" && email !== "" && message !== "" && subject !== "") {
      setIsFilled(true);
    } else {
      setIsFilled(false);
    }
  }, [name, email, message, subject]);

  return (
    <motion.div
      className="form2-container"
      initial={{ y: 30, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ delay: 0.5, duration: 0.3, ease: "easeOut" }}
    >
      <div className="form-box">
        <div className="section-title">HELLO!</div>
        <form
          name="contact_version0"
          method="post"
          data-netlify="true"
          onSubmit="submit"
          data-netlify-honeypot="bot-field"
        >
          <input type="hidden" name="form-name" value="contact_version0" />
          <div hidden>
            <input name="bot-field" />
          </div>

          <input
            className="input-field"
            type="text"
            name="Name"
            placeholder="Name*"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <div className="border-bottom"></div>

          <input
            className="input-field"
            type="email"
            name="Email"
            placeholder="Example@email.com*"
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <div className="border-bottom"></div>

          <input
            className="input-field"
            type="text"
            name="Subject"
            placeholder="Subject*"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required
          />
          <div className="border-bottom"></div>

          <textarea
            name="Message"
            rows="4"
            placeholder="Message*"
            onChange={(e) => setMessage(e.target.value)}
            required
          ></textarea>
          <div className="border-bottom"></div>
          <button
            type="submit"
            disabled={isFilled ? false : true}
            className={isFilled ? "send-button-box active" : "send-button-box"}
          >
            <div className="text">SEND</div>
            <div className="icon">
              <SiMinutemailer />
            </div>
          </button>
        </form>
      </div>
    </motion.div>
  );
};

export default Form2;
