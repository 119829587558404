import React from "react";
import {
  TiSocialLinkedinCircular,
  TiSocialInstagramCircular,
  TiSocialFacebookCircular,
} from "react-icons/ti";
import ResumeLink from "./KarenaHuang.pdf";
import ns_logo_mini from "./ns_logo_mini.png";

const Footer = () => {
  const getYear = () => new Date().getFullYear();

  return (
    <footer className="footer-container">
      <div className="footer-box">
        <div className="left">
          <div className="date">© {getYear()} Karena Huang</div>
          <div className="message">All Rights Reserved</div>
          <div className="built-by-wrapper">
            <div className="line"></div>
            <a
              href="https://nikolayshatalov.com/"
              rel="noopener noreferrer"
              target="_blank"
            >
              <div className="built-by-box">
                <div className="text">Built by </div>
                <div className="image-box">
                  <img src={ns_logo_mini} alt="" />
                </div>
              </div>
            </a>
          </div>
        </div>
        <div className="right">
          <div className="contact-box">
            <a
              href="mailto:karena.huangchenwei@gmail.com"
              rel="noopener noreferrer"
              target="_blank"
            >
              karena.huangchenwei@gmail.com
            </a>
            <div className="resume-box">
              <a href={ResumeLink} download>
                Resume
              </a>
            </div>
          </div>
          <div className="social-box">
            <div className="social">
              <a
                href="https://www.linkedin.com/in/karenahuang/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <div className="icon">
                  <TiSocialLinkedinCircular />
                </div>
              </a>
            </div>
            <div className="social">
              <a
                href="https://www.instagram.com/karenahuang/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <div className="icon">
                  <TiSocialInstagramCircular />
                </div>
              </a>
            </div>
            <div className="social">
              <a
                href="https://www.facebook.com/karena.huang.9"
                rel="noopener noreferrer"
                target="_blank"
              >
                <div className="icon">
                  <TiSocialFacebookCircular />
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
