import { useState } from "react";
import { ClipLoader } from "react-spinners";

const COLORS = ["#FED16D", "#92C1B7", "#EF926C", "#73A7CD"];

const Item = ({ subhref }) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleLoadingToggle = () => setIsLoading(false);

  const getRandomColor = () =>
    COLORS[Math.floor(Math.random() * COLORS.length)];

  const randColor = getRandomColor();

  return (
    <div className="sub-img-box">
      {isLoading && (
        <div
          style={{
            width: "100%",
            height: "200px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <ClipLoader color={randColor} />
        </div>
      )}
      <img
        src={subhref}
        alt=""
        onLoad={handleLoadingToggle}
        style={{ display: isLoading ? "none" : "block" }}
      />
    </div>
  );
};

export default Item;
