import React from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  const subNavData = [
    { name: "MAIN", color: "#FED16D", to: "/" },
    { name: "PACKAGING", color: "#92C1B7", to: "/packaging" },
    { name: "BRANDING", color: "#EF926C", to: "/branding" },
    { name: "ABOUT", color: "#73A7CD", to: "/about" },
  ];

  const subNavItems = subNavData.map((item, idx) => {
    const { name, color, to } = item;
    return (
      <Link to={to} key={idx}>
        <div className={`category ${name}`} style={{ backgroundColor: color }}>
          {name}
        </div>
      </Link>
    );
  });
  return (
    <>
      <nav className="navbar-container">
        <div className="logo-box">
          <Link to="/">Karena Huang</Link>
        </div>
      </nav>
      <section className="navbar-sub-container">
        <div className="sub-box">{subNavItems}</div>
      </section>
    </>
  );
};

export default Navbar;
