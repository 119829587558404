import { useEffect } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Item from "./Item";

const Details = (props) => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  const { name, subhrefs, description, role, video } =
    props.location.state.project;

  const roleDisplay =
    typeof role === "string" ? role : role.map((item) => <div>{item}</div>);

  return (
    <section className="details-container">
      <div className="main-img-box">
        <div className="info">
          <div className="name">{name}</div>
          <div className="description">
            <div className="header">DESCRIPTION</div>
            <div className="text">{description}</div>
          </div>
          <div className="role">
            <div className="header">ROLE</div>
            <div className="text">{roleDisplay}</div>
          </div>
        </div>
      </div>
      <div className="video-box">
        {video ? (
          <div className="iframe-video">
            <iframe
              width="560"
              height="315"
              src={video}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        ) : (
          ""
        )}
      </div>
      <ResponsiveMasonry
        columnsCountBreakPoints={{ 350: 1, 750: 2 }}
        className="images-box"
      >
        <Masonry>
          {subhrefs.map((subhref, idx) => (
            <Item subhref={subhref} key={idx} />
          ))}
        </Masonry>
      </ResponsiveMasonry>
    </section>
  );
};

export default Details;
