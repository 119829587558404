import React, { useEffect, useState } from "react";
import { TiArrowUpOutline } from "react-icons/ti";

const Widget = () => {
  const [isVisible, setIsVisible] = useState(true);

  const handleScroll = () => {
    const scroll = document.querySelector("#widget-icon");
    window.addEventListener("scroll", function () {
      scroll.style.transform = "rotate(" + window.pageYOffset + "deg)";
      fadeMenuWrap();
      moveWidget();
    });

    scroll.addEventListener("click", function () {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    });
  };

  const fadeMenuWrap = () => {
    var scrollPos = window.pageYOffset || document.documentElement.scrollTop;
    if (scrollPos > 400) {
      let element = document.querySelector(".widget-box");
      element.setAttribute("style", "opacity: 1;");
    } else {
      let element = document.querySelector(".widget-box");
      element.removeAttribute("style", "opacity: 0;");
    }
  };

  const moveWidget = () => {
    if (
      window.pageYOffset + window.innerHeight >=
      document.body.scrollHeight - 100
    ) {
      const widgetCont = document.querySelector(".widget-container");
      widgetCont.setAttribute("style", "opacity: 0;");
      if (isVisible) setIsVisible(false);
    } else {
      if (isVisible === false) {
        const widgetCont = document.querySelector(".widget-container");
        widgetCont.setAttribute("style", "opacity: 1;");
        setIsVisible(true);
      }
    }
  };

  useEffect(() => {
    handleScroll();
  }, [isVisible]);

  return (
    <div className="widget-container">
      <div className="widget-box">
        <button className="icon" id="widget-icon">
          <TiArrowUpOutline />
        </button>
      </div>
    </div>
  );
};

export default Widget;
