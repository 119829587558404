export const clientsData = [
    {
        clientId: '1',
        name: "DUNKIN' DONUTS",
        logo: './images/clients/dunkinLogo.png',
        link: 'https://www.dunkindonuts.com/en'
    },
    {
        clientId: '2',
        name: 'THE CENTER LGBT',
        logo: './images/clients/thecenterlogo.png',
        link: 'https://gaycenter.org/'
    },
    {
        clientId: '3',
        name: "TARGET",
        logo: './images/clients/targetlogo.png',
        link: 'https://www.target.com/'
    },
    {
        clientId: '4',
        name: "AIRPARIS",
        logo: './images/clients/airparislogo.png',
        link: 'https://www.airparisagency.com/'
    },
    {
        clientId: '5',
        name: 'SEPHORA',
        logo: './images/clients/sephoralogo.png',
        link: 'https://www.sephora.com/'
    },
    {
        clientId: '6',
        name: 'MILK MAKEUP',
        logo: './images/clients/milkmakuplogo.png',
        link: 'https://www.milkmakeup.com/'
    },
    {
        clientId: '7',
        name: 'OVERTHROW NYC',
        logo: './images/clients/overthrowlogo.png',
        link: 'https://overthrownyc.com/'
    },
    {
        clientId: '8',
        name: "COSTCO",
        logo: './images/clients/costcologo.png',
        link: 'https://www.costco.com/'
    },
    {
        clientId: '9',
        name: "DISNEY",
        logo: './images/clients/disneylogo.png',
        link: 'https://www.disney.com/'
    },
    {
        clientId: '10',
        name: "MARVEL",
        logo: './images/clients/marvellogo.png',
        link: 'https://www.marvel.com/'
    },
    {
        clientId: '11',
        name: 'URBAN OUTFITTERS',
        logo: './images/clients/urbanlogo.png',
        link: 'https://www.urbanoutfitters.com'
    },
    {
        clientId: '12',
        name: "SIOBEAUTY",
        logo: './images/clients/siologo.png',
        link: 'https://www.siobeauty.com/'
    }
]