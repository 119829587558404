export const masterData = [
  {
    num: "1",
    category: "branding",
    name: "HOSTEL BRANDING",
    href: "./images/hostel1.jpg",
    subhrefs: [
      "/images/hostel1.jpg",
      "/images/hostel11.jpg",
      "/images/hostel12.jpg",
      "/images/hostel13.jpg",
      "/images/hostel14.jpg",
    ],
    cardInfo: "Come together. Start the city here.",
    description:
      "CANOPY hostel with new scandinavian touch branding system. Come together. Start the city here.",
    role: "Brand Identity + Logo Design + Visual System",
  },
  {
    num: "2",
    category: "packaging",
    name: "MAKE MOVES WITH FLEX",
    href: "./images/package3.jpg",
    subhrefs: [
      "/images/package3.jpg",
      "/images/package31.JPG",
      "/images/package32.JPG",
      "/images/package33.JPG",
      "/images/package34.JPG",
    ],
    cardInfo:
      "Sustainable and recyclable gift send to celebrate new product launch.",
    description:
      "Tribe Influencer Gifting. Sustainable, Recyclable Package & Reusable Tote Bag.",
    role: "Visual Identity + Branded Packaging Design",
  },
  {
    num: "3",
    category: "packaging",
    name: "WATERMELON",
    href: "/images/package1.jpg",
    subhrefs: [
      "/images/package1.jpg",
      "/images/package11.jpg",
      "/images/package12.jpg",
      "/images/package13.jpg",
      "/images/package14.jpg",
    ],
    cardInfo: "Bringing ingredients story to packaging design.",
    description:
      "tribe influencer gifting. To bring the ingredients story to this watermelon-shape inspired packaging design. Soft touch and spot gloss varnish effect.",
    role: "Visual Identity + Conceptual Packaging Design",
  },
  {
    num: "4",
    category: "branding",
    name: "Sio Beauty",
    href: "/images/Sio1.jpg",
    subhrefs: [
      "/images/Sio1.jpg",
      "/images/Sio2.jpg",
      "/images/Sio3.jpg",
      "/images/Sio4.jpg",
      "/images/Sio5.jpg",
      "/images/Sio6.jpg",
      "/images/Sio10.jpg",
      "/images/Sio8.jpg",
      "/images/Sio9.jpg",
      "/images/Sio7.jpg",
      "/images/Sio11.jpg",
    ],
    cardInfo: "Bring the color story to skincare and beauty world.",
    description:
      "Sio Beauty primary and secondary design. Created overall brand aesthetic mood board, color palette and concept sketches. Using Brand voice to bring the story to skincare and beauty world.",
    role: ["Art Direction + Visual Identity", "Product & Packaging Design"],
  },
  {
    num: "5",
    category: "branding",
    name: "WU-TANG x 美",
    href: "/images/branding1.jpg",
    subhrefs: [
      "/images/branding1.jpg",
      "/images/branding11.jpg",
      "/images/branding12.jpg",
      "/images/branding13.jpg",
    ],
    video: "https://www.youtube.com/embed/G7s5rbM9J3w",
    cardInfo: "The takeover with the branding collaboration.",
    description:
      "ENTER THE REALM. The empowering philosophies of Wu-Tang x Milk Makeup merge to give you ceremonial beauty tools to help unearth your deep inner wisdom and the raw power of the Wu. To bring the world of the Wu Warrior alive, we teamed up with Teyana Taylor, an artist whose talents defy categorization, in a cinematic campaign video produced by Whooden Collective and directed by Milk Makeup Co- Founder Georgie Greville. Logo design is inspired by Chinese beauty character.",
    role: "Brand Identity + Logo Design",
  },
  {
    num: "6",
    category: "packaging",
    name: "Every day is Taco Chewsday!",
    href: "/images/bark1.jpg",
    subhrefs: [
      "/images/bark1.jpg",
      "/images/bark2.jpg",
      "/images/bark3.jpg",
      "/images/bark4.jpg",
    ],
    cardInfo: "BARK Jalapeño Pupper Dog Toy Collection.",
    description:
      "BARK Jalapeño Pupper Dog Toy Collection. Worked with toy & copy team to design and illustrate the graphics that tells the story from taco Tuesday with a touch of humor and hidden jokes. Created the same theme on packaging and retail display at Target to feel cohesive on the overall collection.",
    role: "Art Direction + Illustration + Retail Display",
  },
  {
    num: "7",
    category: "branding",
    name: "LIVE YOUR LOOK",
    href: "/images/merch4.jpg",
    subhrefs: ["/images/merch4.jpg", "/images/merch41.jpg"],
    cardInfo: "Carry your own bag to live your look.",
    description:
      "DOT COM Cyber Monday Collective Gifting. Tyvek Material Reusable Bag.",
    role: "Visual Merchandise + Brand Identity Design",
  },
  {
    num: "8",
    category: "packaging",
    name: "FOUR TWENTY 2.0",
    href: "/images/package8.jpg",
    subhrefs: [
      "/images/package8.jpg",
      "/images/package81.jpg",
      "/images/package82.jpg",
      "/images/package83.jpg",
      "/images/package84.jpg",
    ],
    cardInfo: "Game-changing sealed shipper for another hit.",
    description:
      "Tribe Influencer Gifting. Custom Vacuum sealed package shipper bag with embossed cover. Partnership with G-Pen & Sundae School.",
    role: "Visual Identity + Package Design",
  },
  {
    num: "9",
    category: "branding",
    name: "STUDIOFAM",
    href: "/images/sticker1.jpg",
    subhrefs: ["/images/sticker1.jpg", "/images/sticker11.jpg"],
    cardInfo: "A new way to express gift system. Repeat.",
    description:
      "DOT COM visual system design in collateral cards and seal tapes.",
    role: "Brand Identity + Visual System",
  },
  {
    num: "10",
    category: "packaging",
    name: "GLITTER UP",
    href: "/images/product4.jpg",
    subhrefs: [
      "/images/product4.jpg",
      "/images/product41.jpg",
      "/images/product42.jpg",
      "/images/product43.jpg",
    ],
    cardInfo: "A new applicator to cheer up your makeup collection.",
    description:
      "Milk Makeup Clear Glitter Silicone Applicator. Holographic Pouch & Glitter Effect Box.",
    role: "Product + Packaging Design + Production",
  },
  {
    num: "11",
    category: "branding",
    name: "EVERYDAY I SMILE",
    href: "/images/sticker2.jpg",
    subhrefs: [
      "/images/sticker2.jpg",
      "/images/sticker21.jpg",
      "/images/sticker22.jpg",
    ],
    cardInfo: "Self-promotion stickers design with daily essential.",
    description:
      "Self-promotion in visual system by illustrate multi sticker designs.",
    role: "Illustration + Visual Identity",
  },
  {
    num: "12",
    category: "branding",
    name: "OVERTHROW NYC",
    href: "/images/event1.jpg",
    subhrefs: [
      "/images/event1.jpg",
      "/images/event11.jpg",
      "/images/event12.jpg",
      "/images/event13.jpg",
    ],
    cardInfo: "Activation collection to kick off PR boxing event in rings.",
    description: "Milk Makeup x Overthrow NYC Boxing PR Event.",
    role: "Visual Merchandise + Brand Merch Design.",
  },
  {
    num: "13",
    category: "packaging",
    name: "K U S H VAULT",
    href: "/images/package2.jpg",
    subhrefs: [
      "/images/package2.jpg",
      "/images/package21.jpg",
      "/images/package22.jpg",
      "/images/package23.jpg",
    ],
    cardInfo: "Boom. Multi-culture cross road to celebrate new launch.",
    description:
      "Tribe Influencer Gifting. Partnership with G-Pen & Novel Studios. Moiré Text Pattern Effect. Silver Foil Printing Marketing Cards. Stackable Foam Tray & Magnetic Closure Box.",
    role: "Visual Identity + Packaging Design",
  },
  {
    num: "14",
    category: "branding",
    name: "LA DIDOT",
    href: "/images/branding7.jpg",
    subhrefs: [
      "/images/branding7.jpg",
      "/images/branding71.jpg",
      "/images/branding72.jpg",
      "/images/branding73.jpg",
    ],
    cardInfo: "Modern French restaurant branding identity.",
    description: "Brand identity for a modern french restaurant in Brooklyn.",
    role: "Brand Identity + Visual System",
  },
  {
    num: "15",
    category: "packaging",
    name: "CLEAN, COOL BEAUTY.",
    href: "/images/poster1.jpg",
    subhrefs: ["/images/poster1.jpg"],
    cardInfo: "9 ways to bring clean, cool beauty to live your look.",
    description: " Campaign images and visual system design",
    role: "Visual Identity + Poster Design",
  },
  {
    num: "16",
    category: "packaging",
    name: "B.Y.O.B BLUR",
    href: "/images/package4.jpg",
    subhrefs: [
      "/images/package4.jpg",
      "/images/package41.jpg",
      "/images/package42.jpg",
      "/images/package43.jpg",
      "/images/package44.jpg",
    ],
    cardInfo: "Meet the newest member of the blur family and B.Y.O.B.",
    description:
      "tribe influencer gifting. Scanimation pull sleeve design. Staircase drawer system package. Magnetic closure box.",
    role: "Visual Identity + Conceptual Packaging Design",
  },
  {
    num: "17",
    category: "packaging",
    name: "THE CENTER = PRIDE",
    href: "/images/product1.jpg",
    subhrefs: [
      "/images/product1.jpg",
      "/images/product11.jpg",
      "/images/product12.jpg",
      "/images/product13.jpg",
      "/images/product1.jpg",
    ],
    cardInfo: "All we need is love. Wear it and carry it to support LGBT.",
    description:
      "All we need is LOVE. The way to show our love to support LGBT community. With Pride Month on the horizon, Milk Makeup unveils its third collaboration with The Center. This time around, the makeup brand debuts the newly created “Wear Your Pride Set” as a white clutch that features a rainbow zipper detail. All net proceeds of the “Wear Your Pride Set” of up to $50,000 USD will be donated to The Center. The donation will help The Center achieve “its mission to empower people to lead healthy, successful lives, celebrate diversity and advocate for justice and opportunity.” According to an official press release, Milk Makeup will also be in New York City celebrating the first-ever World Pride Parade which marks the 50th anniversary of the Stonewall Uprising on June 30th.",
    role: "Visual Identity + Product Design",
  },
  {
    num: "18",
    category: "packaging",
    name: "EYE PIGMENT",
    href: "/images/product7.jpg",
    subhrefs: ["/images/product7.jpg", "/images/product71.jpg"],
    description:
      "bold statement in a swipe. 12 shades longwear cream eyeshadow.",
    cardInfo: "Bold statement in a swipe.",
    role: "Product + Packaging Design + Production",
  },
];
