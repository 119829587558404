import React, { useState } from "react";
import { Link } from "react-router-dom";
import { HiArrowRight } from "react-icons/hi";
import { motion } from "framer-motion";
import { ClipLoader } from "react-spinners";

const COLORS = ["#FED16D", "#92C1B7", "#EF926C", "#73A7CD"];

const Project = ({ project }) => {
  const { num, name, href, cardInfo, category } = project;

  const [isLoading, setIsLoading] = useState(true);

  const handleLoadingToggle = () => setIsLoading(false);

  const getRandomColor = () =>
    COLORS[Math.floor(Math.random() * COLORS.length)];

  const randColor = getRandomColor();

  return (
    <Link to={{ pathname: `/${category}/${num}`, state: { project } }}>
      <motion.div
        className="project-container"
        initial={{ y: 30, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ delay: 0.2, duration: 0.3, ease: "easeOut" }}
      >
        {isLoading && (
          <div
            style={{
              width: "100%",
              height: "200px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ClipLoader color={randColor} />
          </div>
        )}
        <div className="img-box">
          <img
            src={href}
            alt=""
            onLoad={handleLoadingToggle}
            style={{ display: isLoading ? "none" : "block" }}
          />

          <div className="content-box">
            <ul className="content">
              <li className="name">{name}</li>
              <li className="info">{cardInfo}</li>
            </ul>
          </div>

          {/* mobile only */}
          <div className="bottom-box">
            <div className="name">{name}</div>
            <div className="see-more-box">
              <div className="see-more">See More</div>
              <div className="icon">
                <HiArrowRight />
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </Link>
  );
};

export default Project;
