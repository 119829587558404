import React, { useEffect } from "react";
import { HiDownload } from "react-icons/hi";
import { motion } from "framer-motion";
import Typing from "react-typing-animation";

import Form2 from "./Form2";
import Clients from "./Clients";
import ResumeLink from "../footer/KarenaHuang.pdf";

const About = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    scrollToTop();
  }, []);

  return (
    <section className="about-container">
      <div className="about-box">
        <div className="img-container">
          <div className="info-box">
            <div className="left-box">
              <div className="one">
                Hi! I'm
                <Typing speed={150}>
                  <Typing.Delay ms={1500} />
                  <span>Karena</span>
                </Typing>
              </div>
              <div className="two">the Designer</div>
            </div>
            <div className="right-box">
              <motion.div
                className="intro"
                initial={{ y: 30, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.2, duration: 0.3, ease: "easeOut" }}
              >
                I am an art director and a creative designer based in New York
                City. I've developed my professional skills through St. Louis,
                Chicago, Los Angeles to New York. As a creative individual and
                dedicated collaborator, my focus and passion lie in packaging
                design, branding direction and events collateral.
                <div className="sub-intro">
                  Always a dog &#62; cat person. Let's connect!
                </div>
              </motion.div>

              <motion.div
                className="resume-box"
                initial={{ y: 30, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ delay: 0.7, duration: 0.3, ease: "easeOut" }}
              >
                <a href={ResumeLink} download>
                  RESUME
                </a>
                <div className="icon">
                  <HiDownload />
                </div>
              </motion.div>
            </div>
          </div>
          <Form2 />
          <Clients />
        </div>
      </div>
    </section>
  );
};

export default About;
